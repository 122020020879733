export default function IconbRaS() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path
                d="M16.0118 4.06445H7.54836C6.5596 4.06445 5.82196 4.87116 5.82196 5.78622V25.7331C5.82196 26.6132 6.77584 27.3155 7.68205 26.854L15.9926 22.6169L23.7457 26.8465L23.7602 26.854C24.6658 27.3155 25.6197 26.6132 25.6197 25.7325V14.4151"
                stroke="black"
                strokeWidth="1.5059"
            />
            <path d="M17.4516 7.12891H25.822" stroke="black" strokeWidth="1.5059" strokeLinecap="round" />
            <path d="M21.6371 3L21.6371 11.2562" stroke="black" strokeWidth="1.5059" strokeLinecap="round" />
        </svg>
    );
}
